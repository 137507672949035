<template>
  <div class="accountManage">
    <a-page-header
      title="用户管理"
      style="padding-left: 0; margin-bottom: 10px; border-bottom: 1px solid rgb(235, 237, 240)"
    />
    <a-form layout="inline">
      <a-form-item label="用户昵称：">
        <a-input v-model="searchForm.nickname" placeholder="请输入用户昵称" style="width: 200px" />
      </a-form-item>
      <a-form-item label="手机号码：">
        <a-input v-model="searchForm.mobile" placeholder="请输入手机号" style="width: 200px" />
      </a-form-item>
      <a-form-item label="用户ID：">
        <a-input v-model="searchForm.userNumber" placeholder="请输入用户ID" style="width: 200px;margin-left:14px" />
      </a-form-item>
      <a-form-item label="实名认证：">
        <a-select
          v-model="searchForm.realNameAuthentication"
          style="width: 200px"
          placeholder="全部"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in roleNameOptions" :key="option.id" :value="option.id">
            {{ option.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
<!--       <a-form-item label="所属项目：">-->
<!--        <a-select-->
<!--          v-model="searchForm.userProjects"-->
<!--          style="width: 200px"-->
<!--          placeholder="请选择所属项目"-->
<!--          :getPopupContainer="(triggerNode) => triggerNode.parentNode"-->
<!--        >-->
<!--          <a-select-option v-for="option in optionsAll" :key="option.id" :value="option.id">-->
<!--            {{ option.project_name }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-item>-->
      <a-form-item label="所属项目">
        <a-tree-select
          v-model="searchForm.userProjects"
          style="width: 200px"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择所属项目"
          allow-clear
          tree-default-expand-all
        >
          <a-tree-select-node :disabled="item.disabled" :value="item.id" :title="item.project_name" v-for="item in projectList" :key="item.id">
            <a-tree-select-node :key="arr.id" :value="arr.id" :title="arr.project_name" v-for="arr in item.child">
            </a-tree-select-node>
          </a-tree-select-node>
        </a-tree-select>
      </a-form-item>
       <a-form-item label="注册渠道：">
        <a-select
          v-model="searchForm.registerSource"
          style="width: 200px"
          placeholder="全部"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in registerSourceOptions" :key="option.id" :value="option.id">
            {{ option.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
       <a-form-item label="注册时间：">
           <a-range-picker format="YYYY/MM/DD" v-model="time" style="width: 200px" @change="onChangeTime" :getCalendarContainer="(triggerNode) => triggerNode.parentNode" />
      </a-form-item>
       <a-form-item label="用户状态：">
        <a-select
          v-model="searchForm.deleted"
          style="width: 200px"
          placeholder="全部"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in deletedOptions" :key="option.id" :value="option.id">
            {{ option.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px">
          <a-icon type="search" />
          查询
        </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">
          <a-icon type="redo" />
          重置
        </a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px" v-if="$checkButtonPermissions($buttonPermissionsConfig.runUser.export)">
      <a-button type="primary" @click="exportList">
        导出
      </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
      <span slot="userNumber" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{record.userNumber}}
          </template>
            <span class="field">{{record.userNumber}}</span>
        </a-tooltip>
      </span>
      <span slot="nickname" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{record.nickname}}
          </template>
            <span class="field">{{record.nickname}}</span>
        </a-tooltip>
      </span>
      <span slot="mobile" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{record.mobile}}
          </template>
            <span class="field">{{record.mobile}}</span>
        </a-tooltip>
      </span>
      <span slot="gmtCreate" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{record.gmtCreate}}
          </template>
            <span class="field">{{record.gmtCreate}}</span>
        </a-tooltip>
      </span>
      <span slot="realNameAuthentication" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            <span v-if="record.realNameAuthentication == 0">未认证</span>
            <span v-else-if="record.realNameAuthentication == 1">已认证</span>
          </template>
            <span class="field" v-if="record.realNameAuthentication == 0">未认证</span>
            <span class="field" v-else-if="record.realNameAuthentication == 1">已认证</span>
        </a-tooltip>
        </span>
        <span slot="enterpriseCertification" slot-scope="text, record" class="action">
          <a-tooltip>
            <template slot="title">
              <span v-if="record.enterpriseCertification == 0">未认证</span>
              <span v-else-if="record.enterpriseCertification == 1">已认证</span>
            </template>
              <span class="field" v-if="record.enterpriseCertification == 0">未认证</span>
              <span class="field" v-else-if="record.enterpriseCertification == 1">已认证</span>
          </a-tooltip>
        </span>
        <span slot="subordinateCompanies" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{record.subordinateCompanies}}
          </template>
            <span class="field">{{record.subordinateCompanies}}</span>
        </a-tooltip>
      </span>
      <span slot="companyProjects" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{record.companyProjects}}
          </template>
            <span class="field">{{record.companyProjects}}</span>
        </a-tooltip>
      </span>
        <span slot="registerSource" slot-scope="text, record" class="action">
          <a-tooltip>
            <template slot="title">
              <span v-if="record.registerSource == 'web_ies' || record.registerSource == 'saas_platform'">PC</span>
              <span v-else-if="record.registerSource == 'wx_login' || record.registerSource == 'WX_LOGIN'">微信小程序</span>
              <span v-else-if="record.registerSource == 'app_iyou'">APP</span>
            </template>
              <span class="field" v-if="record.registerSource == 'web_ies' || record.registerSource == 'saas_platform'">PC</span>
              <span class="field" v-else-if="record.registerSource == 'wx_login' || record.registerSource == 'WX_LOGIN'">微信小程序</span>
              <span class="field" v-else-if="record.registerSource == 'app_iyou'">APP</span>
          </a-tooltip>
        </span>
        <span slot="userProjects" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{record.userProjects}}
          </template>
            <span class="field">{{record.userProjects}}</span>
        </a-tooltip>
      </span>
        <span slot="openCard" slot-scope="text, record" class="action">
          <a-tooltip>
            <template slot="title">
              <span v-if="record.openCard == 0">未开卡</span>
              <span v-else-if="record.openCard == 1">已开卡</span>
            </template>
              <span class="field" v-if="record.openCard == 0">未开卡</span>
              <span class="field" v-else-if="record.openCard == 1">已开卡</span>
          </a-tooltip>

        </span>
        <span slot="deleted" slot-scope="text, record" class="action">
          <a-tooltip>
            <template slot="title">
              <span v-if="record.deleted == 0">正常</span>
              <span v-else-if="record.deleted == 1">已注销</span>
              <span v-else-if="record.deleted ==2">冻结</span>
            </template>
              <span class="field" v-if="record.deleted == 0">正常</span>
              <span class="field" v-else-if="record.deleted == 1">已注销</span>
              <span class="field" v-else-if="record.deleted ==2">冻结</span>
          </a-tooltip>

        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a href="javascript:;" @click="detail(text, record)" v-if="$checkButtonPermissions($buttonPermissionsConfig.runUser.look)">查看</a>
          <a v-if="$checkButtonPermissions($buttonPermissionsConfig.runUser.edit) && record.enterpriseCertification == 0" href="javascript:;" @click="edit(text, record)">修改</a>
          <a href="javascript:;" v-if="$checkButtonPermissions($buttonPermissionsConfig.runUser.freeze) && record.deleted === 0" @click="freeze('冻结', record)">冻结</a>
          <a href="javascript:;" v-else-if="$checkButtonPermissions($buttonPermissionsConfig.runUser.freeze) && record.deleted === 2" @click="freeze('解冻', record)">解冻</a>
        </span>
      </a-table>
    </div>
    <a-modal v-model="visibleFreeze" title="关联账户" @ok="freezeSure">
        <span v-if="text === '冻结'">是否确认冻结？</span>
        <span v-else-if="text === '解冻'">是否确认解冻？</span>
    </a-modal>
    <a-modal v-model="visibleExport" title="导出" @ok="exportSure" :confirmLoading="exportLoading">
        <span>是否确认导出数据？</span>
    </a-modal>
  </div>
</template>

<script>
import * as api from "@/api/userMessage";
import { mapState } from "vuex";

export default {
  name: "AccountManage",
  components: {
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
      projectList: (state) => {
        return state.common.projects.length && state.common.projects.map(item => {
          return {
            ...item,
            disabled: !!(item.child)
          }
        })
      }
    }),
  },
  data() {
    return {
        time:[],
        startTime:"",
        endTime:"",
        exportLoading: false,
        searchForm:{
            nickname:"",
            mobile:"",
            userNumber:"",
            realNameAuthentication:null,
            // enterpriseAuthentication:null,
            userProjects:undefined,
            registerSource:null,
            deleted:null,
        },
        roleNameOptions:[
            {
                id: null,
                value:"全部"
            },
            {
                id:1,
                value:"已认证"
            },
            {
                id:'0',
                value:"未认证"
            }
        ],
        enterpriseAuthenticationOptions:[
            {
                id: null,
                value:"全部"
            },
            {
                id:1,
                value:"已认证"
            },
            {
                id:2,
                value:"未认证"
            }
        ],
        registerSourceOptions:[
            {
                id: null,
                value:"全部"
            },
            {
                id: "APP",
                value:"APP"
            },
            {
                id: "微信小程序",
                value:"微信小程序"
            },
            {
                id: "PC",
                value:"PC"
            }
        ],
        deletedOptions:[
            {
                id: null,
                value:"全部"
            },
            {
                id:'0',
                value:"正常"
            },
            {
                id:2,
                value:"冻结"
            },
            {
                id:1,
                value:"已注销"
            }
        ],
        table: {
        //数据
        dataInfo: [],
        //title数据
        columns: [
          {
            title: "用户ID",
            dataIndex: "userNumber",
            key: "userNumber",
            // width: "90px",
            scopedSlots: { customRender: "userNumber" },
          },
          {
            title: "用户昵称",
            dataIndex: "nickname",
            key: "nickname",
            // width: "100px",
            scopedSlots: { customRender: "nickname" },
          },
          {
            title: "用户手机号",
            dataIndex: "mobile",
            key: "mobile",
            // width: "120px",
            scopedSlots: { customRender: "mobile" },
          },
          {
            title: "注册时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            // width: "130px",
            scopedSlots: { customRender: "gmtCreate" },
          },
          {
            title: "是否实名认证",
            dataIndex: "realNameAuthentication",
            key: "realNameAuthentication",
            // width: "120px",
            scopedSlots: { customRender: "realNameAuthentication" },
          },
          {
            title: "是否企业认证",
            dataIndex: "enterpriseCertification",
            key: "enterpriseCertification",
            // width: "120px",
            scopedSlots: { customRender: "enterpriseCertification" },
          },
          {
            title: "所属公司",
            dataIndex: "subordinateCompanies",
            key: "subordinateCompanies",
            // width: "150px",
            scopedSlots: { customRender: "subordinateCompanies" },
          },
          {
            title: "公司所属项目",
            dataIndex: "companyProjects",
            key: "companyProjects",
            // width: "150px",
            scopedSlots: { customRender: "companyProjects" },
          },
          {
            title: "注册渠道",
            dataIndex: "registerSource",
            key: "registerSource",
            // width: "90px",
            scopedSlots: { customRender: "registerSource" },
          },
          {
            title: "用户所属项目",
            dataIndex: "userProjects",
            key: "userProjects",
            // width: "150px",
            scopedSlots: { customRender: "userProjects" },
          },
          {
            title: "是否开卡",
            dataIndex: "openCard",
            key: "openCard",
            // width: "100px",
            scopedSlots: { customRender: "openCard" },
          },
          {
            title: "用户状态",
            dataIndex: "deleted",
            key: "deleted",
            // width: "100px",
            scopedSlots: { customRender: "deleted" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      tenantId: JSON.parse(localStorage.getItem("user")),
        visibleFreeze:false,
        text:"",
        freezeData:{},
        visibleExport: false,
        // 列表操作按钮权限配置
        actions: [
          this.$buttonPermissionsConfig.runUser.look,
          this.$buttonPermissionsConfig.runUser.edit,
          this.$buttonPermissionsConfig.runUser.freeze
        ]
    };
  },
  mounted(){
      this.getUserList()
  },
  methods: {
    async getUserList(){
       const formData = {
        pageNum: this.table.pagination.current,
        pageSize: this.table.pagination.pageSize,
        startTime: this.time.length !== 0 ? this.dateChangeFormat('YYYY-mm-dd', this.time[0]) + ' 00:00:00' : null,
        endTime: this.time.length !== 0 ? this.dateChangeFormat('YYYY-mm-dd', this.time[1]) + ' 23:59:59' : null
        // ...this.searchForm
      }
      for (const key in this.searchForm) {
        if(this.searchForm[key]) {
          formData[key] = this.searchForm[key]
        }
      }
      formData.userProjects = this.searchForm.userProjects ? this.searchForm.userProjects.split(",") : null
      const res = await api.getUserList(formData)
      if(res.code === "200"){
        this.table.dataInfo = res.data.users
        this.table.pagination.total = res.data.count
      }
    },
    dateChangeFormat(format, date) {
      date = new Date(date);
      const dataItem = {
        'Y+': date.getFullYear().toString(),
        'm+': (date.getMonth() + 1).toString(),
        'd+': date.getDate().toString(),
        'H+': '00',
        'M+': '00',
        'S+': '00',
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(ret[1], ret[1].length === 1 ? dataItem[item] : dataItem[item].padStart(ret[1].length, '0'));
        }
      });
      return format;
    },
    //查询
    search() {
      this.table.pagination.current = 1
      this.getUserList()
    },
    //重置
    resetSearchForm() {
      this.searchForm = {
        nickname:"",
        mobile:"",
        userNumber:"",
        realNameAuthentication:null,
        // enterpriseAuthentication:0,
        userProjects:undefined,
        project:null,
        registerSource:null,
        deleted:null,
      };
      this.time = ""
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.exportLoading = false
      this.getUserList()
      //TODO: 调用接口进行查询项目列表
    },
    // 导出
    exportList(){
      this.exportLoading = false
      this.visibleExport = true
    },
    async exportSure(){
      this.exportLoading = true
      const formData = {
        startTime: this.time.length !== 0 ? this.dateChangeFormat('YYYY-mm-dd HH:MM:SS', this.time[0]) : null,
        endTime: this.time.length !== 0 ? this.dateChangeFormat('YYYY-mm-dd HH:MM:SS', this.time[1]) : null
      }
      for (const key in this.searchForm) {
        if(this.searchForm[key]) {
          formData[key] = this.searchForm[key]

        }
      }
      formData.userProjects = this.searchForm.userProjects ? this.searchForm.userProjects.split(",") : null
      const res = await api.getUserExport(formData)
      if(res.status == 200){
        const blob = new Blob([res.data]);
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = objectUrl;
        // a.download = "用户管理.xlsx";
        a.setAttribute("download","用户管理.xlsx")
        a.click();
        this.exportLoading = false
        this.visibleExport = false
      }
    },
    onChangeTime(val){
        console.log(val)
    },
    //详情
    detail(text,val) {
      console.log(text,val)
      this.$router.push({
          path:"/runUser/detail",
          query:{flag:2,val:val.userId}
      })
      this.handleStatus = 2;
      this.isShow = true;
    },
    //编辑
    edit(text,val) {
      console.log(text,val)
      this.$router.push({
          path:"/runUser/detail",
          query:{flag:1,val:val.userId}
      })
      this.handleStatus = 3;
      this.isShow = true;
    },
    //解冻/冻结
    freeze(text,val) {
      this.visibleFreeze = true
      this.freezeData = val
      this.text = text
    },
    async freezeSure(){
      const data = {
        userId: this.freezeData.userId,
        status: this.text === "解冻" ? 0 : 1
      }
      const res = await api.freezeUser(data.userId,data.status)
      if(res.code === "200"){
        this.$message.success(this.text + '成功')
        this.getUserList()
        this.visibleFreeze = false
      }
    },
    //禁用
    forbidden() {
      this.$confirm({
        title: "是否要禁用该账户?",
        content: () => <div style="color:red;">确定要禁用该账户</div>,
        icon: () => <a-icon type="close-circle" style="color: red" />,
        onOk() {
          console.log("OK");
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getUserList()
    }
  },
  created() {
    this.$checkTableActionIsShow(this.actions) && this.table.columns.push(this.$backTableAction({width: "200px"}));
  }
};
</script>

<style lang="scss" scoped>
.action a {
  color: #1890ff;
  margin-right:15px
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width:80px;
}
</style>
